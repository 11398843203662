import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as S from "./style";

function FooterMain() {
  const history = useHistory();
  const { t } = useTranslation();
  const { dataCategoriesTerm } = useSelector(
    (state: any) => state.dataCategoriesTerm
  );

  return (
    <S.Wrapper id="footer">
      <S.Container>
        <div className="step">
          <S.Title>COMPANY</S.Title>
          <S.Box>
            <div className="item">
              <span className="value">{t("FOOTER_COMPANY_TITLE")}</span>
            </div>
            <div className="item">
              <span className="value">|</span>
            </div>
            <div className="item">
              <span className="key">대표자 </span>
              <span className="value">김재욱 </span>
            </div>
            <div className="item">
              <span className="value">|</span>
            </div>
            <div className="item">
              <span className="key">사업자등록번호 </span>
              <span className="value">664-88-00573</span>
            </div>
          </S.Box>
          <S.Box className="mt-18">
            <div className="item">
              <span className="key">통신판매업 신고번호 </span>
              <span className="value">2021-충남아산-0558호</span>
            </div>
            <div className="item">
              <span className="value">|</span>
            </div>
            <div className="item">
              <span className="key">개인정보관리책임자 </span>
              <span className="value">김재욱</span>
            </div>
          </S.Box>
          <S.SubBox>
            <div className="title">주소</div>
            <div className="item">
              <span className="key">서울 </span>
              <span className="value">
                06182 서울 강남구 영동대로 407, 코니빌딩 4층
              </span>
            </div>
            <div className="item">
              <span className="key">충남 </span>
              <span className="value">
                31470 충남 아산시 배방읍 희망로46번길 45-11, 405호
              </span>
            </div>
          </S.SubBox>
        </div>
        <div className="step">
          <S.Title className="title-modified">CONTACT</S.Title>
          <S.Box>
            <div className="item">
              <span className="key">Company Mail (사업제휴 / 회사문의) </span>
              <span className="value">info@artnguide.com</span>
            </div>
          </S.Box>
          <S.Box>
            <div className="item">
              <span className="key">고객문의 </span>
              <span className="value">cs@artnguide.com / 070-5015-0772</span>
            </div>
          </S.Box>
          <S.Box>
            <div className="item">
              <span className="value">
                * 주말 및 공휴일에는 상담이 어렵습니다
              </span>
            </div>
          </S.Box>
          <S.SubBox>
            <div className="title">운영시간 </div>
            <div className="item">
              <span className="value">
                평일 10:00 ~ 18:00
                <br /> (점심시간 12:00~13:00)
              </span>
            </div>
          </S.SubBox>
        </div>
      </S.Container>
      <S.ContainerTerm>
        <div className="container-term-list">
          <div className="item">
            {dataCategoriesTerm?.map((dt: any, i: any) => (
              <div className="term">
                <div key={i} onClick={() => history.push(dt.location)}>
                  {dt.title}
                </div>
                {i != dataCategoriesTerm.length - 1 ? <div>|</div> : <></>}
              </div>
            ))}
          </div>
          <S.TextMin>© Yeolmaecompany. all rights reserved</S.TextMin>
        </div>
      </S.ContainerTerm>
    </S.Wrapper>
  );
}

export default FooterMain;
