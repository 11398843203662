import { Empty } from "antd";
import { UrlInternal } from "common/constants/endpoints";
import useWindowSize, { Size } from "hooks/useWindowSize";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react"; //lazy
import { useHistory } from "react-router-dom";
import ImgGift from "assets/images/_main_slider/gift.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getTimeRemaning } from "utils/getTime";
import Countdown from "react-countdown";
import CircleProgress from "../CircularProgress";
import * as S from "./style";
import { useTranslation } from "react-i18next";
import moneyConvertLang from "utils/moneyConvertLang";
import { formatPrice } from "utils/formatPrice";

interface Props {
  data?: any;
}

const MainSlider = ({ data = [] }: Props) => {
  const slider: any = useRef<any>(null);
  const { width }: any = useWindowSize();
  const [showItem, setShowItem] = useState<any>({ show: false, item: "" });
  const { t } = useTranslation();
  const size: Size = useWindowSize();
  const isMobile = size.width && size.width <= 765;
  const [sliderData, setSliderData] = useState<any>(null);

  useEffect(() => {
    renderDataSliderForMobile();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 500,
  };

  const nextToSlide = useCallback(() => {
    slider.current.slickNext();
  }, []);

  const backToSlide = useCallback(() => {
    slider.current.slickPrev();
  }, []);

  const showInfo = (show: any, item: number) => {
    setShowItem({ show, item });
  };

  const handleCheckTimeEndEvent = (eventEndDate: string) => {
    if (eventEndDate) {
      let presentDate = moment(moment().toISOString(), "YYYY-MM-DD HH:mm:ss");
      let lastDate = moment(eventEndDate, "YYYY-MM-DD HH:mm:ss");
      let result = lastDate.diff(presentDate);
      return result <= 0 ? false : true;
    } else {
      return true;
    }
  };

  const renderDataSlider = () => {
    let dt = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (handleCheckTimeEndEvent(data[i].eventEndDate)) {
          dt.push(data[i]);
        }
      }
    }
    return dt;
  };

  const renderDataSliderForMobile = () => {
    let dt = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (handleCheckTimeEndEvent(data[i].eventEndDate)) {
          dt.push(data[i]);
        }
      }
    }

    setSliderData(dt[0]);
  };

  const checksizeChart = (v: any) => {
    const value = v?.isUpcomming
      ? 0
      : moment(v?.endNormalBuyAt) > moment()
      ? v?.v3TotalUnitReg * v?.unitPrice
      : 0;

    if (value >= 1000000000000) {
      return "15px";
    } else if (value >= 100000000000) {
      return "16px";
    } else if (value >= 10000000000) {
      return "18px";
    } else if (value >= 1000000000) {
      return "19px";
    } else {
      return "22px";
    }
  };

  return (
    <>
      {renderDataSlider()?.length > 0 && (
        <S.Container className="main-slider">
          {!isMobile ? (
            <>
              <Slider ref={slider} {...settings} useTransform={false}>
                {renderDataSlider()?.map((v: any, i: any) => (
                  <S.SlideItem key={i}>
                    <div
                      className={`item item-left ${
                        showItem?.show && showItem?.item === i ? "showInfo" : ""
                      }`}
                    >
                      <div className="item-left__title type-gp">
                        {v?.isUpcomming ? "모집예정 청약" : "모집중인 청약"}
                      </div>
                      <div className="item-left__title">
                        <span>{v?.title.split("(")[0]}</span>
                        <br />
                        <span>{"(" + v?.title.split("(")[1]}</span>
                      </div>
                      <div className="item-left__art">
                        {v?.thumbnail ? (
                          <img src={v?.thumbnail} alt="art" />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No Image"
                          />
                        )}
                      </div>
                      <div className="item-left__size">
                        {v?.authorName}, {v?.artworkName}
                        {v?.artworkName && ","}
                      </div>
                      <div className="item-left__size">
                        {`${v?.artSize ? v?.artSize?.trim() + "," : ""} ${
                          v?.artMaterial
                            ? v?.artMaterial?.trim() +
                              (v?.yearOfItem ? "," : "")
                            : ""
                        } ${v?.yearOfItem?.trim()}`}
                      </div>
                      {data.length > 1 && (
                        <div
                          className="ti ti-arrow-circle-left back"
                          onClick={backToSlide}
                        ></div>
                      )}
                      <div
                        className="open-information"
                        onClick={() => {
                          window.location.href = `${
                            v?.isUpcomming
                              ? UrlInternal.GROUP_SALE_COMMING
                              : UrlInternal.GROUP_SALE_ON_ORDER
                          }/${v?.id}`;
                        }}
                      >
                        <span>청약 신청하기</span>
                      </div>
                    </div>
                    <div className="item-line"></div>
                    <div
                      className="item item-right"
                      onClick={() =>
                        width <= 768 ? showInfo(!showItem?.show, i) : () => {}
                      }
                    >
                      <div className="item-right__title">
                        {v?.isUpcomming ? "모집예정 청약" : "모집중인 청약"}
                      </div>
                      <div className="item-right__price">
                        <div className="item-right__price-left">
                          <span className="text-price">발행금액</span>
                          <span className="value-price">
                            {moneyConvertLang(v?.target)}
                          </span>
                        </div>
                        <div className="item-right__price-line"></div>
                        <div className="item-right__price-left">
                          <span className="text-price">모집금액</span>
                          <span className="value-price">
                            {moneyConvertLang(v?.target * 0.9)}
                          </span>
                        </div>
                      </div>
                      {["EVENT_RB", "EVENT_ARB"].includes(
                        v?.eventType ?? ""
                      ) ? (
                        <>
                          <img src={ImgGift} alt="img gift" />
                          <div className="item-right__box-price">
                            <div className="item-right__box-price--title">
                              {t("HOME_PRICE")}
                            </div>
                            <div className="item-right__box-price--price">
                              {moneyConvertLang(v?.target)}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="item-right__chart">
                            {v?.gpVer === "V3" ? (
                              <CircleProgress
                                percentage={
                                  ((v?.v3TotalUnitReg * v?.unitPrice) /
                                    (v?.target * 0.9)) *
                                  100
                                }
                                content={
                                  <S.Content>
                                    <span
                                      className="percentage"
                                      style={{ fontSize: checksizeChart(v) }}
                                    >
                                      {v?.isUpcomming
                                        ? "0"
                                        : moment(v?.endNormalBuyAt).isAfter(
                                            moment()
                                          )
                                        ? `${formatPrice(
                                            v?.v3TotalUnitReg * v?.unitPrice
                                          )}`
                                        : "0"}
                                    </span>
                                    <span className="chart-line"></span>
                                    <span className="chart-content">
                                      누적신청금액
                                    </span>
                                  </S.Content>
                                }
                                trailColor="#91BEF1"
                                pathColor="#F37E80"
                              />
                            ) : (
                              <CircleProgress
                                percentage={v?.targetPercent}
                                content={
                                  <S.Content>
                                    <span className="percentage">
                                      {v?.targetPercent}%
                                    </span>
                                    <span className="chart-line"></span>
                                    <span className="chart-content">
                                      {t("SLIDER_PROGRESS1")}
                                    </span>
                                  </S.Content>
                                }
                                trailColor="#91BEF1"
                                pathColor="#F37E80"
                              />
                            )}
                          </div>
                          <div className="item-right__price">
                            <div className="item-right__price-left">
                              <span className="text-price">주당 금액</span>
                              <span className="value-price">
                                {moneyConvertLang(v?.unitPrice)}
                              </span>
                            </div>
                            <div className="item-right__price-line"></div>
                            <div className="item-right__price-right">
                              <div className="item-right__tab-item">
                                <span>
                                  청약 시작일 : {""}
                                  {moment(v?.startDate).format("YYYY. MM. DD")}
                                </span>
                              </div>
                              <div className="item-right__tab-item">
                                <span>
                                  청약 마감일 : {""}
                                  {moment(v?.endNormalBuyAt).format(
                                    "YYYY. MM. DD"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <button
                        className="item-right__btn"
                        onClick={() => {
                          window.location.href = `${
                            v?.isUpcomming
                              ? UrlInternal.GROUP_SALE_COMMING
                              : UrlInternal.GROUP_SALE_ON_ORDER
                          }/${v?.id}`;
                        }}
                      >
                        청약 신청하기
                        {` `}
                        <i className="ti ti-angle-right"></i>
                      </button>
                      {data.length > 1 && (
                        <div
                          className="ti ti-arrow-circle-right next"
                          onClick={nextToSlide}
                        ></div>
                      )}
                    </div>
                  </S.SlideItem>
                ))}
              </Slider>
            </>
          ) : (
            <Slider ref={slider} {...settings} useTransform={false}>
              <S.SlideItem key={1}>
                <div
                  className={`item item-left ${
                    showItem?.show && showItem?.item === 1 ? "showInfo" : ""
                  }`}
                >
                  <div className="item-left__title type-gp">
                    {sliderData?.isUpcomming
                      ? "모집예정 청약"
                      : "모집중인 청약"}
                  </div>
                  <div className="item-left__title">
                    <span>{sliderData?.title.split("(")[0]}</span>
                    <br />
                    <span>{"(" + sliderData?.title.split("(")[1]}</span>
                  </div>
                  <div className="item-left__art">
                    {sliderData?.thumbnail ? (
                      <img src={sliderData?.thumbnail} alt="art" />
                    ) : (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No Image"
                      />
                    )}
                  </div>
                  <div className="item-left__size">
                    {sliderData?.authorName}, {sliderData?.artworkName}
                    {sliderData?.artworkName && ","}
                  </div>
                  <div className="item-left__size">
                    {`${
                      sliderData?.artSize
                        ? sliderData?.artSize?.trim() + ","
                        : ""
                    } ${
                      sliderData?.artMaterial
                        ? sliderData?.artMaterial?.trim() +
                          (sliderData?.yearOfItem ? "," : "")
                        : ""
                    } ${sliderData?.yearOfItem?.trim()}`}
                  </div>
                  {data.length > 1 && (
                    <div
                      className="ti ti-arrow-circle-left back"
                      onClick={backToSlide}
                    ></div>
                  )}
                  <div
                    className="open-information"
                    onClick={() => {
                      window.location.href = `${
                        sliderData?.isUpcomming
                          ? UrlInternal.GROUP_SALE_COMMING
                          : UrlInternal.GROUP_SALE_ON_ORDER
                      }/${sliderData?.id}`;
                    }}
                  >
                    <span>청약 신청하기</span>
                  </div>
                </div>
              </S.SlideItem>
              <S.SlideItem key={2}>
                <div
                  className="item item-right"
                  onClick={() =>
                    width <= 768 ? showInfo(!showItem?.show, 2) : () => {}
                  }
                >
                  <div className="item-right__title">
                    {sliderData?.isUpcomming
                      ? "모집예정 청약"
                      : "모집중인 청약"}
                  </div>
                  <div className="item-right__price">
                    <div className="item-right__price-left">
                      <span className="text-price">발행금액</span>
                      <span className="value-price">
                        {moneyConvertLang(sliderData?.target)}
                      </span>
                    </div>
                    <div className="item-right__price-line"></div>
                    <div className="item-right__price-left">
                      <span className="text-price">모집금액</span>
                      <span className="value-price">
                        {moneyConvertLang(sliderData?.target * 0.9)}
                      </span>
                    </div>
                  </div>
                  {["EVENT_RB", "EVENT_ARB"].includes(
                    sliderData?.eventType ?? ""
                  ) ? (
                    <>
                      <img src={ImgGift} alt="img gift" />
                      <div className="item-right__box-price">
                        <div className="item-right__box-price--title">
                          {t("HOME_PRICE")}
                        </div>
                        <div className="item-right__box-price--price">
                          {moneyConvertLang(sliderData?.target)}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="item-right__chart">
                        {sliderData?.gpVer === "V3" ? (
                          <CircleProgress
                            percentage={
                              ((sliderData?.v3TotalUnitReg *
                                sliderData?.unitPrice) /
                                (sliderData?.target * 0.9)) *
                              100
                            }
                            content={
                              <S.Content>
                                <span
                                  className="percentage"
                                  style={{
                                    fontSize: checksizeChart(sliderData),
                                  }}
                                >
                                  {sliderData?.isUpcomming
                                    ? "0"
                                    : moment(
                                        sliderData?.endNormalBuyAt
                                      ).isAfter(moment())
                                    ? `${formatPrice(
                                        sliderData?.v3TotalUnitReg *
                                          sliderData?.unitPrice
                                      )}`
                                    : "0"}
                                </span>
                                <span className="chart-line"></span>
                                <span className="chart-content">
                                  누적신청금액
                                </span>
                              </S.Content>
                            }
                            trailColor="#91BEF1"
                            pathColor="#F37E80"
                          />
                        ) : (
                          <CircleProgress
                            percentage={sliderData?.targetPercent}
                            content={
                              <S.Content>
                                <span className="percentage">
                                  {sliderData?.targetPercent}%
                                </span>
                                <span className="chart-line"></span>
                                <span className="chart-content">
                                  {t("SLIDER_PROGRESS1")}
                                </span>
                              </S.Content>
                            }
                            trailColor="#91BEF1"
                            pathColor="#F37E80"
                          />
                        )}
                      </div>
                      <div className="item-right__price">
                        <div className="item-right__price-left">
                          <span className="text-price">주당 금액</span>
                          <span className="value-price">
                            {moneyConvertLang(sliderData?.unitPrice)}
                          </span>
                        </div>
                        <div className="item-right__price-line"></div>
                        <div className="item-right__price-right">
                          <div className="item-right__tab-item">
                            <span>
                              청약 시작일 : {""}
                              {moment(sliderData?.startDate).format(
                                "YYYY. MM. DD"
                              )}
                            </span>
                          </div>
                          <div className="item-right__tab-item">
                            <span>
                              청약 마감일 : {""}
                              {moment(sliderData?.endNormalBuyAt).format(
                                "YYYY. MM. DD"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <button
                    className="item-right__btn"
                    onClick={() => {
                      window.location.href = `${
                        sliderData?.isUpcomming
                          ? UrlInternal.GROUP_SALE_COMMING
                          : UrlInternal.GROUP_SALE_ON_ORDER
                      }/${sliderData?.id}`;
                    }}
                  >
                    청약 신청하기
                    {` `}
                    <i className="ti ti-angle-right"></i>
                  </button>
                  <div
                    className="ti ti-arrow-circle-right next"
                    onClick={nextToSlide}
                  ></div>
                </div>
              </S.SlideItem>
            </Slider>
          )}
        </S.Container>
      )}
    </>
  );
};

export default MainSlider;
